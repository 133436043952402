var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lens-card border-bottom mt-2"},[_c('b-row',{staticClass:"mb-2 text-center text-sm-left"},[_c('b-col',{staticClass:"pr-3 pr-xl-0",attrs:{"sm":"3","md":"4","lg":"4"}},[_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('img',{staticClass:"mt-1",attrs:{"src":require("@/assets/shopping-cart/lens.svg")}})])],1),_c('b-row',[_c('b-col',{staticClass:"text-center"},[(_vm.showPrimaryLensStatus)?_c('p',{staticClass:"mt-4",class:{'font-weight-bold': _vm.lens.isPrimaryLens}},[_vm._v(" "+_vm._s((_vm.lens.isPrimaryLens ? _vm.t(_vm.primaryLensStatusKeyText) : _vm.t(_vm.additionalLensStatusKeyText)) + " - " + (_vm.lens.opEye))+" ")]):_vm._e()])],1)],1),_c('b-col',{staticClass:"pl-3 pl-xl-0",attrs:{"sm":"4","md":"4","lg":"4"}},[_c('h5',{staticClass:"mt-1 mb-1 font-weight-bold"},[_vm._v(_vm._s(_vm.lens.description))]),(_vm.canShowPrice)?_c('h6',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.lens.price))+" ")]):_vm._e(),_c('h6',[_c('span',{class:{'mr-2': _vm.lens.serial}},[_vm._v(_vm._s(_vm.lens.serial))]),_vm._v(" "+_vm._s(_vm.lens.modelNumber)+" ")]),_c('h6',[_vm._v(_vm._s(_vm.prescription))]),(_vm.lens.patientId)?_c('h6',[_vm._v(" "+_vm._s(_vm.t('checkout_Calculation'))+": "),(_vm.lens.dataSourceId === _vm.DATA_SOURCES.OCOS)?_c('span',{staticClass:"mr-1 text-red"},[_c('b-img',{staticClass:"mr-1 pb-1",attrs:{"src":_vm.padLockIcon,"height":"18","width":"18"}}),_vm._v(" OCOS ")],1):_vm._e(),_c(_vm.lens.dataSourceId !== _vm.DATA_SOURCES.OCOS && _vm.canGoPreopData
                            ? 'router-link'
                            : 'span',{tag:"router-link",staticClass:"text-primary-light font-weight-bold",attrs:{"to":{
                        name: 'PreOpData',
                        params: {
                            patientId: _vm.lens.patientId,
                            preOpDataSetId: _vm.lens.preOpDataSetId,
                        },
                    }}},[_c('u',[_vm._v(_vm._s(_vm.lens.patientOcosId)+" - "+_vm._s(_vm.lens.opEye))])])],1):_vm._e(),(_vm.lens.doctorId)?_c('h6',[_vm._v(" "+_vm._s(_vm.t('surgeon'))+": "),_c('router-link',{staticClass:"text-primary-light font-weight-bold",attrs:{"to":{
                        name: 'SurgeonDetails',
                        params: {doctorId: _vm.lens.doctorId},
                    }}},[_c('u',[_vm._v(_vm._s(_vm.lens.doctorOcosId)+" - "+_vm._s(_vm.lens.doctorName))])])],1):_vm._e()]),_c('b-col',{attrs:{"sm":"5","md":"4","lg":"4"}},[_c('div',{staticClass:"float-sm-right"},[_c('b-button',{staticClass:"mt-1 mr-2",class:{'hidden-element': _vm.readonly || !_vm.showAddButtom},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('on-add', _vm.lens)}}},[_vm._v(" "+_vm._s(_vm.t(_vm.addButtomKeyText))+" ")]),(!_vm.readonly && _vm.showRemoveButtom)?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"secondary"},on:{"click":function($event){_vm.showRemovePrompt = true}}},[_vm._v(" "+_vm._s(_vm.t(_vm.removeButtomKeyText))+" ")]):_vm._e(),_vm._t("custom-actions-before",null,{"props":_vm.$data}),_c('h6',{staticClass:"mt-2 font-weight-bold",class:{
                        'text-center':
                            (!_vm.showAddButtom && !_vm.showRemoveButtom) || _vm.readonly || _vm.isConsignment,
                    }},[_vm._v(" "+_vm._s(_vm.t(_vm.isConsignment ? 'consignment_NameCaps' : 'staarName'))+" ")]),_vm._t("custom-actions-after",null,{"props":_vm.$data}),_c('h6',{class:( _obj = {}, _obj['hidden-element'] = !_vm.showExpirationWindow || !_vm.lens.expirationWindow, _obj )},[_vm._v(" "+_vm._s(_vm.lens.expirationWindow)+" "),(!_vm.readonly)?_c('span',{staticClass:"ml-3 text-danger"},[_vm._v(" "+_vm._s(_vm.lens.daysTillExpiration)+" "+_vm._s(_vm.t('checkout_DaysRemaining'))+" ")]):_vm._e()])],2)]),_c('LensPrompt',{attrs:{"show":_vm.showRemovePrompt,"lens":_vm.lens,"showExpirationWindow":_vm.showExpirationWindow,"removePromptKeyText":_vm.removePromptKeyText,"isConfirmButttonDisabled":_vm.isConfirmButttonDisabled},on:{"update:show":function($event){_vm.showRemovePrompt=$event},"on-remove":function($event){return _vm.$emit('on-remove', $event)}}},[_c('template',{slot:"remove-prompt-before-card"},[_vm._t("remove-prompt-before-card",null,{"props":_vm.$data})],2),_c('template',{slot:"remove-prompt-after-card"},[_vm._t("remove-prompt-after-card",null,{"props":_vm.$data})],2)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }